export async function discordSay(message) {
    const token = process.env.KS_MERCHANT_DISCORD_WEBHOOK_URL;
    const params = {
      username: "Thirst Logger",
      avatar_url: "",
      content: message
    }
    
    if(token) {
      fetch(token,{
        "method":"POST",
        "headers":{"content-type": "application/json"},
        "body":JSON.stringify(params)
      }); 
    }
}

export async function discordSayToken(message, token) {
  const params = {
    username: "Thirst Logger",
    avatar_url: "",
    content: message
  }
  
  if(token) {
    fetch(token,{
      "method":"POST",
      "headers":{"content-type": "application/json"},
      "body":JSON.stringify(params)
    }); 
  }
}

