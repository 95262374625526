import DB, { sha256, fieldsForSelect, fromAurora, sanitizeData, AURORA_TYPE } from '@backend/data_access/DB';
import { log } from '@/lib/Logger';
import { APPROVED_STATUS } from 'lib/Constants';

class Stores {

  static async carryingProduct(product_slug) {
    try {    
      const db = new DB();
      const selectSql = `
        SELECT
          stores.id,
          stores.name,
          stores.slug,
          stores.delivers_to_cities,
          stores.operating_schedule,
          stores.shipping_method,
          stores.closed_by_admin,
          product_stores.price,
          product_stores.discount,
          product_stores.available_stock
        FROM stores
        LEFT JOIN product_stores ON product_stores.store_id = stores.id
        LEFT JOIN products ON product_stores.product_id = products.id
        WHERE products.slug = :product_slug
      `;
      const rawdata = await db.executeStatement(selectSql, [
        { name: 'product_slug', value: { stringValue: product_slug } },
      ]);

      if (rawdata.records.length === 0) return { data: [] };   

      const data =  rawdata.records.map(([
        {longValue: id},
        {stringValue: name},
        {stringValue: slug},
        {stringValue: delivers_to_cities},
        {stringValue: operating_schedule},
        {stringValue: shipping_method},
        {booleanValue: closed_by_admin},
        {stringValue: price},
        {stringValue: discount},
        {longValue: available_stock}
      ]) => ({
        id: id,
        name: name,
        slug: slug ?? null,
        delivers_to_cities: delivers_to_cities,
        operating_schedule: operating_schedule,
        shipping_method: shipping_method,
        closed_by_admin,
        price: price,
        discount: discount,
        available_stock: available_stock
      }));

      return { data };

    } catch (error) {
      log(error.stack);
      throw error; //throw error after logging so that the application handles the error
    }
  }

  static async get() {
    try {    
      const db = new DB();
      const selectSql = `SELECT 
        city,
        address1,
        zip,
        region,
        delivers_to_cities
      FROM stores
      WHERE status = 'approved' AND operating_schedule is not null`;
      const rawdata = await db.executeStatement(selectSql, []);
      if (rawdata.records.length === 0) return null;   

      const data =  rawdata.records.map(([
        {stringValue: city},
        {stringValue: address1},
        {longValue: zip},
        {stringValue: region},
        {stringValue: delivers_to_cities},
      ]) => ({
        city: city ?? null,
        address1: address1 ?? null,
        zip: zip ?? null,
        region: region ?? null,
        delivers_to_cities: delivers_to_cities ?? null,
      }));

      return { data };

    } catch (error) {
      log(error.stack);
      throw error; //throw error after logging so that the application handles the error
    }
  }

  static async whereIds(ids, product_id) {
    try {    
      const db = new DB();
      const selectSql = `
        SELECT
          stores.id,
          stores.name,
          stores.delivers_to_cities,
          stores.operating_schedule,
          stores.shipping_method,
          stores.closed_by_admin,
          product_stores.price,
          product_stores.discount,
          product_stores.available_stock,
          AVG(order_details.merchant_rating) AS merchant_rating
        FROM stores
        LEFT JOIN order_details ON order_details.store_id = stores.id
        INNER JOIN product_stores ON product_stores.store_id = stores.id
        WHERE stores.id in (${ids.map(id => parseInt(id)).join(",")}) AND product_id = :product_id
        GROUP BY stores.id
        ORDER BY order_details.merchant_rating DESC
      `;
      const rawdata = await db.executeStatement(selectSql, [
        { name: 'product_id', value: { longValue: product_id } },
      ]);

      if (rawdata.records.length === 0) return { data: [] };   

      const data =  rawdata.records.map(([
        {longValue: id},
        {stringValue: name},
        {stringValue: delivers_to_cities},
        {stringValue: operating_schedule},
        {stringValue: shipping_method},
        {booleanValue: closed_by_admin},
        {stringValue: price},
        {stringValue: discount},
        {longValue: available_stock},
        {doubleValue: merchant_rating}
      ]) => ({
        id: id,
        name: name,
        delivers_to_cities: delivers_to_cities,
        operating_schedule: operating_schedule,
        shipping_method: shipping_method,
        closed_by_admin,
        price: price,
        discount: discount,
        available_stock: available_stock,
        merchant_rating: merchant_rating ?? null
      }));

      return { data };

    } catch (error) {
      log(error.stack);
      throw error; //throw error after logging so that the application handles the error
    }
  }
  
  static async getStore(id) {
    try {    
      const db = new DB();
      const selectSql = `
        SELECT
          id
          ,name
          ,slug
          ,delivers_to_cities
          ,region
          ,city
          ,address1
          ,barangay
          ,street_name
          ,province
          ,zip
          ,mobile_number
          ,contact_person
          ,operating_schedule
          ,shipping_method
          ,email
          ,merchant_id
          ,messerve_id
          ,closed_by_admin
        FROM stores
        WHERE id = ${id}
      `;
      const rawdata = await db.executeStatement(selectSql, []);
      if (rawdata.records.length === 0) return null;
        
      const data =  rawdata.records.map(([
        {longValue: id},
        {stringValue: name},
        {stringValue: slug},
        {stringValue: delivers_to_cities},
        {stringValue: region},
        {stringValue: city},
        {stringValue: address1},
        {stringValue: barangay},
        {stringValue: street_name},
        {stringValue: province},
        {longValue: zip},
        {stringValue: mobile_number},
        {stringValue: contact_person},
        {stringValue: operating_schedule},
        {stringValue: shipping_method},
        {stringValue: email},
        {stringValue: merchant_id},
        {stringValue: messerve_id},
        {booleanValue: closed_by_admin}
      ]) => ({
        id,
        name,
        slug,
        delivers_to_cities,
        region,
        city,
        address1,
        barangay,
        street_name,
        province,
        zip,
        mobile_number,
        contact_person,
        operating_schedule,
        shipping_method,
        email,
        merchant_id,
        messerve_id,
        closed_by_admin
      }));
      return { data };

    } catch (error) {
      log(error.stack);
      throw error; //throw error after logging so that the application handles the error
    }
  }

  static async updateStoreDetails(id, name, region, address1, barangay, street_name, province, city, zip, mobile_number, contact_person, operating_schedule, delivers_to_cities, shipping_method, email) {
    try {    
      const db = new DB();

      const updateSql = `
        UPDATE stores
        SET 
          name = '${name}',
          region = '${region}',
          address1 = '${address1}',
          barangay = '${barangay}',
          street_name = '${street_name}',
          province = '${province}',
          city = '${city}',
          zip = ${zip},
          mobile_number = '${mobile_number}',
          operating_schedule = '${JSON.stringify(operating_schedule)}',
          delivers_to_cities = '${delivers_to_cities}',
          contact_person = '${contact_person}',
          shipping_method= '${shipping_method}',
          email = '${email}'
        WHERE id = '${id}'
      `;

      await db.executeStatement(updateSql, []); 
      return;
      
    } catch (error) {
      log(error.stack);
      throw error; //throw error after logging so that the application handles the error
    }
  }

  static async getStoreDeliveryAreas(status) {
    try {    
      const db = new DB();
      const selectSql = `SELECT delivers_to_cities FROM stores WHERE status = :status AND operating_schedule is NOT NULL`;
      const rawdata = await db.executeStatement(selectSql,  [{ name: 'status', value: { stringValue: status } }]);
      if (rawdata.records.length === 0) return null;   
      const data = rawdata.records.map(([{stringValue: delivers_to_cities}]) => ({delivers_to_cities: delivers_to_cities ?? null}));
      return { data };
    } catch (error) {
      log(error.stack);
      throw error; //throw error after logging so that the application handles the error
    }
  }

  static async getStoreRegions() {
    try {    
      const db = new DB();
      const selectSql = `SELECT region FROM stores WHERE status = :status AND operating_schedule is NOT NULL`;
      const rawdata = await db.executeStatement(selectSql,  [{ name: 'status', value: { stringValue: APPROVED_STATUS } }]);
      if (rawdata.records.length === 0) return null;   
      const data = rawdata.records.map(([{stringValue: region}]) => ({region: region ?? null}));
      return { data };
    } catch (error) {
      log(error.stack);
      throw error; //throw error after logging so that the application handles the error
    }
  }
  static async overrideStoreSchedule(store_id, closed_by_admin) {
    try {
      const db = new DB();
      const updateSql = `UPDATE stores SET closed_by_admin = :closed_by_admin WHERE id = :store_id`;
      const executeStatementParam = {
        store_id: {name: 'store_id', value: {longValue: store_id}},
        closed_by_admin: {name: 'closed_by_admin', value: {booleanValue: closed_by_admin}},
      }
      await db.executeStatement(updateSql,  Object.values(executeStatementParam)); 
      return;
    } catch (error) {
      log(error.stack);
      throw error; //throw error after logging so that the application handles the error
    }
  }

  static async updateDeliveryMethods({ id, shipping_method }) {
    try {    
      const db = new DB();
      const updateSQL = `UPDATE stores 
                         SET shipping_method = :shipping_method 
                         WHERE id = :id`;

      return await db.executeStatement(updateSQL, [
        {name: 'id', value:{ longValue: id }},
        {name: 'shipping_method', value:{ stringValue: JSON.stringify(shipping_method) }}
      ]);
    } catch (error) {
      log(error.stack);
      throw error; //throw error after logging so that the application handles the error
    }
  }
}

export default Stores;
